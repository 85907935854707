<script setup lang="ts">
const authStore = useAuthStore()
authStore.init()

const seoStore = useSeoStore()

watch([
	() => seoStore.title,
	() => seoStore.description,
	() => seoStore.keywords
], () => {
	useSeoMeta({
		title: seoStore.title,
		description: seoStore.description,
		keywords: seoStore.keywords
	})
}, { immediate: true })

</script>

<template>
  <LayoutTop />
  
	<LayoutContainer>
		<slot />
	</LayoutContainer>
  
  <LayoutBottom />
	
	<LayoutCookie v-if="false" />
	
	<UNotifications />
</template>